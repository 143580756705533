'use client'

import { ErrorPage } from '@components/LocalisedError'

export default function LocalErrorBoundary({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return <ErrorPage error={error} reset={reset} />
}
